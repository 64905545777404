// REMIX HMR BEGIN
if (!window.$RefreshReg$ || !window.$RefreshSig$ || !window.$RefreshRuntime$) {
  console.warn('remix:hmr: React Fast Refresh only works when the Remix compiler is running in development mode.');
} else {
  var prevRefreshReg = window.$RefreshReg$;
  var prevRefreshSig = window.$RefreshSig$;
  window.$RefreshReg$ = (type, id) => {
    window.$RefreshRuntime$.register(type, "\"app/routes/$.tsx\"" + id);
  }
  window.$RefreshSig$ = window.$RefreshRuntime$.createSignatureFunctionForTransform;
}
var _s = $RefreshSig$(),
  _s2 = $RefreshSig$();
import * as __hmr__ from "remix:hmr";
if (import.meta) {
  import.meta.hot = __hmr__.createHotContext(
  //@ts-expect-error
  "app/routes/$.tsx");
  import.meta.hot.lastModified = "1728030536059.7163";
}
// REMIX HMR END

"use client";
import { Avatar, Box, Center, Container, Heading, Text, VStack, Wrap, WrapItem } from "@chakra-ui/react";
// or cloudflare/deno
import { json, redirect } from "@remix-run/node";
import { Link, Outlet, isRouteErrorResponse, useLoaderData, useRouteError } from "@remix-run/react";
import { Footer, Frontpage, FrontpageHero, links as frontpageLinks } from "~/components/frontpage";
import { Navigation } from "~/components/navigation";
import { multiSearchQuery } from "~/utils/MeiliSearch";
import Markdown from "markdown-to-jsx";
import redisClient from "~/services/redis.config.server";
import { FETCH_METHODS } from "~/utils/fetchUtils";
import { fetchFromBackendServer } from "~/utils/fetchUtils.server";
import moment from "moment-timezone";
import { useEffect, useState } from "react";
export const links = () => [...frontpageLinks()];
export const headers = ({
  loaderHeaders
}) => ({
  "Cache-Control": loaderHeaders.get("Cache-Control") || "max-age=60, s-maxage=120"
});
export const meta = ({
  data
}) => {
  if (data?.page) {
    return [{
      title: data?.page.title
    }];
  }
  if (data?.category) {
    return [{
      title: data?.category.title
    }];
  }
  return [{
    title: "dealbunny.de"
  }];
};

// Category
export const sitemap = async ({
  config,
  request
}) => {
  const cache = (await redisClient.get("sitemapCategory")) || null;
  const cacheRunning = (await redisClient.get("cacheRunning")) || null;
  if (!!cache) {
    return JSON.parse(cache);
  } else if (cacheRunning) {
    return [];
  }
  await redisClient.set("cacheRunning", moment().toISOString(), "EX", 600);
  const allEntry = [];
  const pageSize = 100;
  let page = 1;
  let total = 0;
  let maxTotal = 2000;
  do {
    const {
      result,
      status
    } = await fetchFromBackendServer(`${process.env.REACT_STRAPI_ACCESS_TOKEN}`, FETCH_METHODS.ENTRIES, "categories", undefined, undefined, {
      sort: ["updatedAt:desc"],
      pagination: {
        page: page++ || 1,
        pageSize: pageSize
      }
    });
    total = result.meta.pagination.total;
    const items = result?.data?.map(item => {
      const itemObj = {
        id: item.id,
        ...item.attributes
      };
      return itemObj;
    }) || [];
    allEntry.push(...items);
    console.log("sitemap deal", total, allEntry.length);
  } while (total > 0 && total > allEntry.length && maxTotal > allEntry.length);
  const dealEntries = allEntry.map(entry => ({
    loc: `/${entry.catURL}`,
    lastmod: moment(entry.updatedAt).utc().format(),
    exclude: !entry.publishedAt // exclude this entry
  }));

  await redisClient.set("sitemapCategory", JSON.stringify(dealEntries), "EX", 60 * 60);
  return dealEntries;
};
export async function loader({
  params
}) {
  const path = params["*"];
  // console.log("params $", params);
  const dealURL = path?.replace(".html", "");
  const pathArray = path?.split("/").filter(val => val != "") || [];
  const category = pathArray.length > 0 ? pathArray[0] : "kategorien";
  const filterNow = `timestamp <= ${Math.round(new Date().getTime() / 1000)}`;
  // console.log(filter);
  const hits = await multiSearchQuery([{
    limit: 1,
    filter: `path = "${path}"`,
    indexUid: "page",
    sort: []
  }, {
    limit: 1,
    filter: `dealURL = "${dealURL}" AND publish = true AND ${filterNow}`,
    sort: []
  }, {
    limit: 1,
    filter: `catURL = "${category}"`,
    indexUid: "category",
    sort: []
  }]);

  // Found Deal
  if (hits[1]?.hits[0]) {
    return redirect("/deal/" + dealURL, 301);
  }
  const total = hits.reduce((accumulator, currentValue) => accumulator + (currentValue?.estimatedTotalHits || 0), 0);
  if (total == 0) {
    throw new Response(null, {
      status: 404,
      statusText: "Not Found"
    });
  }
  return json({
    page: hits[0]?.hits[0],
    category: hits[2]?.hits[0]
  }, {
    status: 200,
    headers: {
      "Cache-Control": "max-age=60, s-maxage=120"
    }
  });
}
export default function Index() {
  _s();
  const data = useLoaderData();
  const [contentRaw, setContentRaw] = useState();
  useEffect(() => {
    if (data.page?.contentRaw && data.page.contentRaw.endsWith(".js")) {
      const script = document.createElement("script");
      script.src = data.page.contentRaw;
      script.async = true;
      script.onload = () => console.log("Script loaded successfully");
      document.body.appendChild(script);
      return () => {
        document.body.removeChild(script);
      };
    } else {
      setContentRaw(data.page?.contentRaw);
    }
  }, [data.page?.contentRaw]);
  return <>
      {data?.category && <Outlet />}
      {data?.page && <FrontpageHero heroImage={data?.page?.titleImage} header={<>
              <Navigation />
              <Center height={{
        base: "200px",
        md: "350"
      }}>
                <VStack>
                  <Heading size={{
            base: "lg",
            md: "xl"
          }} textShadow={"0 4px 8px rgba(0,0,0,.2)"}>
                    {data?.page?.title}
                  </Heading>
                </VStack>
              </Center>
            </>} main={<>
              {data?.page?.content && <Container maxW={"4xl"}>
                  <Markdown options={{
          overrides: {
            h1(props) {
              const {
                node,
                ...rest
              } = props;
              return <Heading size="xl" {...rest} mb={5} mt={10} />;
            },
            h2(props) {
              const {
                node,
                ...rest
              } = props;
              return <Heading size="lg" mb={3} mt={7} {...rest} />;
            },
            h3(props) {
              const {
                node,
                ...rest
              } = props;
              return <Heading size="md" mb={3} mt={7} {...rest} />;
            },
            p(props) {
              const {
                node,
                ...rest
              } = props;
              return <Box fontSize="md" {...rest} mb={3} />;
            },
            Center: {
              component: Center
            },
            WrapComp: {
              component: ({
                children,
                ...props
              }) => <Wrap {...props}>{children}</Wrap>
            },
            WrapItem: {
              component: ({
                children,
                ...props
              }) => <WrapItem {...props}>{children}</WrapItem>
            },
            Avatar: {
              component: Avatar
            }
          }
        }} children={data.page.content} />
                </Container>}

              {contentRaw && <Container maxW={"4xl"} dangerouslySetInnerHTML={{
        __html: contentRaw
      }}></Container>}
            </>}
    // sidebar={<Box mt={{ base: "7", lg: "10" }}></Box>}
    footer={<Footer />} />}
    </>;
}
_s(Index, "+ScCIBweJ0zIDrqzrIlMnTM7qBs=", false, function () {
  return [useLoaderData];
});
_c = Index;
export function ErrorBoundary() {
  _s2();
  const error = useRouteError();
  return <Frontpage main={<VStack w={"100%"} h={"100vh"} p={10}>
          <Heading textAlign={"center"}>
            {isRouteErrorResponse(error) ? `${error.status} ${error.statusText}` : error instanceof Error ? error.message : "Unknown Error"}
          </Heading>
          <Box>
            Seite nicht gefunden. Benötigst du hilfe? Schreib uns eine{" "}
            <Link to={"mailto:support@dealbunny.de"}>
              <Text decoration={"underline"} display={"inline"}>
                Mail
              </Text>
            </Link>
            .
          </Box>
        </VStack>} />;
}
_s2(ErrorBoundary, "oAgjgbJzsRXlB89+MoVumxMQqKM=", false, function () {
  return [useRouteError];
});
_c2 = ErrorBoundary;
var _c, _c2;
$RefreshReg$(_c, "Index");
$RefreshReg$(_c2, "ErrorBoundary");

window.$RefreshReg$ = prevRefreshReg;
window.$RefreshSig$ = prevRefreshSig;